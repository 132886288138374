import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PatientSubmissions } from "../models/patientSubmissions";
import { User } from "../models/user";
import { Subscription } from "rxjs";
import { Patient, patientSubmissionDropdown, submissionStatus, SubmissionStatusColor, submissionTypes } from "../models/patient";
import { UserDataManager } from "../user/UserDataManager";
import { ProgressBarService } from "../services/progress-bar.service";
import { PatientService } from "../services/patient/patient.service";
import { MessageHandlerService } from "../services/message-handler.service";
import {
  adminRoles,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  dateAndTimeFormat,
  dateFormat,
  dateFormatStartWithYear,
  DEFAULT_MRN_LENGTH,
  DEFAULT_PATIENT_ID_LENGTH,
  OnlyAdminAllowedRoles,
  OnlySuperAdminAllowedRoles,
  RoleID,
  rowsPerPageOptions,
  SEARCH_PATIENT_BY_CASE_MANAGER,
  SEARCH_PATIENT_BY_ID,
  SEARCH_PATIENT_BY_NAME_OR_MRN,
  DEFAULT_SEARCH_MIN_CHARACTER,
  QUESTIONAIR_TYPE_ERROR,
  QUESTIONAIR_TYPE_WARN,
  IMPACT,
  QUESTIONAIR_TYPE_WARNING,
  DEFAULT_EPISODE_DATE_FILTER,
} from "../common/Constants";
import { RoleEnum } from "../models/agency";
import { Paginator } from "primeng/paginator";
import { UtilService } from "../services/util/util.service";
import { Episode } from "../models/episode";
import { isNaN } from "lodash-es";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { swapLatitudeAndLongitude } from "../services/util/helper.function";
import { LocalStorageService } from "../services/local-storage.service";
import { questionDescription } from "../helper/question.description";
import { reformatVisitsAnswersData, reformatWithSpecialCharacters } from "../helper/functions";
import { SelectItem } from "primeng/api";
import { visitDetailColors } from "../models/patient";

declare var google: any;
@Component({
  selector: "app-episode",
  templateUrl: "./episode.component.html",
  styleUrls: ["./episode.component.scss"],
})
export class EpisodeComponent implements OnInit, OnDestroy {
  selectedPatients: Patient[];
  selectedCaseManager: any = "All";
  caseManagerFilterNames: any;
  @ViewChild("paginator") paginator: Paginator;
  filteredAgencies: any;
  patientRowSelected: number;
  RoleEnum = RoleEnum;
  defaultMrnLength = DEFAULT_MRN_LENGTH;
  defaultPatientIdLength = DEFAULT_PATIENT_ID_LENGTH;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;

  currentUser: User;
  subscriptions: Subscription[] = [];
  RoleID = RoleID;
  pageNo: number = 0;
  noOfRecordPerRow: number = 30;
  searchByPatient: string = "";
  searchByVisitDate: any;
  searchByVisitDates: any = {};
  selectedAgency: string = "";
  currentAgency: { agency: string; role: string };
  display = false;
  patientSubmission: PatientSubmissions | any;
  patientSubmissionRowSelected: number;
  selectedTable: string;
  selectedVisitDates: string;
  selectedVisitType: string;
  options: any;
  locationPopupDisplay: boolean = false;
  overlays: any;
  submissionStatusColorEnum = SubmissionStatusColor;
  isChangingPage: boolean = false;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  patientSubmissionDropdown = patientSubmissionDropdown;
  selectPatientSubmissionOptions: string = patientSubmissionDropdown[0].value;
  eSOCUploadDisplay: boolean = false;
  loading: boolean = false;

  patients: Patient[] = [];

  totalEpisode: number = 0;
  episodes: Episode[] = [];
  episodeRowSelected: number;
  selectedEpisodes: Episode[];
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  hidePatientName: boolean = false;
  allowAdminRoles: boolean = false;
  rangeDates: Date[];
  selectedVisitDate: any = null;
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  searchPatientByNameOrMrn = SEARCH_PATIENT_BY_NAME_OR_MRN;
  searchPatientById = SEARCH_PATIENT_BY_ID;
  searchByCaseManager: string = "";
  searchByCaseManagerPlaceHolder = SEARCH_PATIENT_BY_CASE_MANAGER;
  selectedCaseManagerName: string[] = [];
  caseManagerFilter: SelectItem[] = [];
  orignalCaseManagerFilterValues: SelectItem[] = [];
  visitDetailColors = visitDetailColors;
  //searchByClinician: string = '';
  //searchByClinicianPlaceHolder = SEARCH_PATIENT_BY_CASE_CLINICIAN;

  constructor(
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    this.setDefaultDtRange();
  }

  setDefaultDtRange() {
    let dd = new Date();
    dd.setDate(dd.getDate() - DEFAULT_EPISODE_DATE_FILTER);
    this.rangeDates = [dd, null];

    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.getDataFromLocalStorage();
    this.getCurrentAgencyRole();
    this.adminRoles();
    this.hideFromDataRoles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getDataFromLocalStorage() {
    const episodeState = JSON.parse(this.localStorageService.get("episodeState"));
    this.searchByPatient = episodeState?.searchByPatient ? episodeState?.searchByPatient : "";
    this.searchByCaseManager = episodeState?.searchByCaseManager ? episodeState?.searchByCaseManager : "";
    this.rangeDates = episodeState?.rangeDates ? episodeState?.rangeDates : null;
    this.selectedCaseManagerName = episodeState?.selectedCaseManagerName ? episodeState?.selectedCaseManagerName : [];

    // if (this.rangeDates && this.rangeDates.length && this.rangeDates[0] && episodeState?.rangeDates) {
    //   let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    //   this.searchByVisitDates.startDate = this.utilService.convertToISOString(startDateFilter, true);
    //   this.rangeDates[0] = new Date(this.rangeDates[0]);
    // }

    // if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1] && episodeState?.rangeDates) {
    //   let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);
    //   this.searchByVisitDates.endDate = this.utilService.convertToISOString(endDateFilter, false);
    //   this.rangeDates[1] = new Date(this.rangeDates[1]);
    // }

    // if (!episodeState?.rangeDates) {
    this.rangeDates = [new Date(new Date().setDate(new Date().getDate() - DEFAULT_EPISODE_DATE_FILTER)), new Date()];
    // }
    this.prePopulateDate();
    this.prePopulatePerPage();
  }

  prePopulateDate() {
    const global = JSON.parse(this.localStorageService.get("globalDates"));
    if (global) {
      this.searchByVisitDates.startDate = this.utilService.convertToISOString(new Date(global.startDate), true);
      this.searchByVisitDates.endDate = this.utilService.convertToISOString(new Date(global.endDate), false);
      this.rangeDates = [new Date(global.startDate), new Date(global.endDate)];
    }
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get("globalPageLimit"));
    if (global) {
      this.noOfRecordPerRow = global.perPage;
    }
  }

  setDataInLocalStorage(key, value) {
    const episodeState: any = JSON.parse(this.localStorageService.get("episodeState"));
    if (episodeState) {
      episodeState[key] = value;
      this.localStorageService.set("episodeState", JSON.stringify(episodeState));
    } else {
      let episodeNewState = {};
      episodeNewState[key] = value;
      this.localStorageService.set("episodeState", JSON.stringify(episodeNewState));
    }
  }

  onSlideBarClose() {
    this.display = false;
  }

  /*  searchByPatient() {
    this.pageNo = 0;
    this.getEpisodes();
  }*/

  /*  isSearchEmpty() {
    if (this.searchPatient === undefined || this.searchPatient === null || this.searchPatient === '') {
      this.pageNo = 0;
      this.getEpisodes();
    }
  }*/

  onlyAdminRoles() {
    return adminRoles.includes(this.currentUser.currentUserRole);
  }

  selectVisitDate() {
    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };
    this.pageNo = 0;
    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.getEpisodes();
    this.utilService.setDataInLocalStorage("globalDates", "startDate", this.rangeDates[0]);
    this.utilService.setDataInLocalStorage("globalDates", "endDate", this.rangeDates[1]);
  }

  onCalendarClear() {
    this.pageNo = 0;
    this.selectedVisitDate = null;
    this.rangeDates = null;
    this.searchByVisitDates = null;
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedVisitDate", this.selectedVisitDate);
    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.getEpisodes();
    this.localStorageService.remove("globalDates");
  }

  getUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        if (res) {
          this.currentUser = res;
        }
      }),
    );
  }

  onSelectedCaseManagerName() {
    this.pageNo = 0;
    this.noOfRecordPerRow = 10;
    this.getEpisodes();
    this.resetPaginator();
  }

  resetPaginator() {
    this.paginator.changePageToFirst(event);
  }

  calculateNumberOfDays(dateString: string | undefined | null): number | null {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDifferenceMs = currentDate.getTime() - date.getTime();
    return Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
  }

  //get episode listing
  getEpisodes() {
    try {
      //this.pageNo = 0;
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;

      let request = {
        pagination: {
          pageNumber: this.pageNo,
          pageSize: this.noOfRecordPerRow,
        },
        search: this.searchByPatient,
        sort: "",
        agencyId: this.currentUser.currentUserRole === RoleID.Admin ? this.selectedAgency : this.currentAgency.agency,
        mrnOrId: "",
        searchByVisitDate: this.searchByVisitDate,
        searchByVisitDates: this.searchByVisitDates,
        submissionView: "Episode_view",
        searchByCaseManager: this.searchByCaseManager ? this.searchByCaseManager : this.selectedCaseManagerName,
      };
      this.patientService.getSubmissionByPatient(request).subscribe((res) => {
        this.episodes = [];
        if (res?.success) {
          if (res.content.totalElements !== 0) {
            this.episodes = res.content.data.map((episode) => {
              const episodeCreatedAt = this.formatDateTime(episode?.submissionData?.episodeCreatedAt ?? episode?.submissionData?.modifiedAt);
              const numberOfDays = this.calculateNumberOfDays(episodeCreatedAt);
              const episodeName = episode?.submissionData?.episodeName ? episode?.submissionData?.episodeName.replace("Episode ", "Ep") : "";
              let totalErrorsAndWarnings = episode.submissionData.submissions.map((submissions) => {
                return {
                  submissionErrorCount: submissions.submissions.submissionErrorCount,
                  submissionEWarningCount: submissions.submissions.submissionEWarningCount,
                  // submissionErrorCount: submissions?.issues.filter((e:any) => e.type === QUESTIONAIR_TYPE_ERROR && e.crossRuleType !== IMPACT).length,
                  // submissionEWarningCount: submissions?.issues.filter((e:any) => e.type === QUESTIONAIR_TYPE_WARN || e.type === QUESTIONAIR_TYPE_WARNING && (e.crossRuleType !== IMPACT)).length
                };
              });

              // Initialize variables to hold the sums
              let totalEpisodeErrors = 0;
              let totalEpisodeWarnings = 0;

              // Use reduce to calculate the sums
              totalErrorsAndWarnings.forEach((item) => {
                totalEpisodeErrors += item.submissionErrorCount;
                totalEpisodeWarnings += item.submissionEWarningCount;
              });

              const patientFirstName = episode.submissionData?.patientData?.firstName ? episode.submissionData?.patientData?.firstName : "";
              const patientLastName = episode.submissionData?.patientData?.lastName ? episode.submissionData?.patientData?.lastName : "";
              const patientName = (patientFirstName ? patientFirstName : "") + " " + (patientLastName ? patientLastName : "");

              return {
                episode: {
                  ...episode.submissionData,
                  episodeName: episodeName,
                  numberOfDays: !isNaN(numberOfDays) ? numberOfDays : "",
                  totalEpisodeErrors: totalEpisodeErrors,
                  totalEpisodeWarnings: totalEpisodeWarnings,
                },
                patient: {
                  ...episode.submissionData?.patientData,
                  patientName: patientName,
                  firstName: OnlySuperAdminAllowedRoles.includes(this.currentUser.currentUserRole)
                    ? "Anonymous"
                    : episode?.submissionData?.patientData?.firstName,
                  lastName: OnlySuperAdminAllowedRoles.includes(this.currentUser.currentUserRole)
                    ? "Anonymous"
                    : episode?.submissionData?.patientData?.lastName,
                  createdAt: this.formatDateTime(episode?.submissionData?.patientData?.createdAt),
                  caseManager: episode?.patient?.caseManager,
                },
                submissions: episode?.submissionData?.submissions,
              };
            });
          } else {
            this.episodes = [];
          }
          this.totalEpisode = res?.content?.totalElements;
        } else if (res) {
          this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
        }
        this.progressBarService.setProgressBarVisibility(false);
        this.loading = false;
      });
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.loading = false;
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  formatDateTime(date: string): string {
    return this.utilService.formatDateTime(date);
  }

  onSelectAgency() {
    this.getEpisodes();
  }

  onEpisodeRowClick(rowIndex: number) {
    this.episodeRowSelected = rowIndex;
  }

  openPatientSubmissionDetails(
    patientSubmissionDetails?: any,
    dob?: string,
    mrn?: string,
    firstName?: string,
    lastName?: string,
    totalErrorAndWarnings?: number,
    picture?: string,
    rowIndex?: number,
    patientId?: string,
    contactInfo?: any,
    icdCodes?: any,
  ) {
    this.display = true;
    this.patientSubmission = patientSubmissionDetails;
    if (!Array.isArray(patientSubmissionDetails?.questionnaire)) {
      this.patientSubmission.questionnaire = this.utilService.extractQuestionAndAnswer(patientSubmissionDetails?.questionnaire);
    } else {
      this.patientSubmission.questionnaire = patientSubmissionDetails?.questionnaire;
    }
    this.patientSubmission["firstName"] = firstName ? firstName : patientSubmissionDetails?.patient?.firstName;
    this.patientSubmission["lastName"] = lastName ? lastName : patientSubmissionDetails?.patient?.lastName;
    const patientFirstName = firstName ? firstName : patientSubmissionDetails?.patient?.firstName;
    const patientLastName = lastName ? lastName : patientSubmissionDetails?.patient?.lastName;
    this.patientSubmission["patientName"] = (patientFirstName ? patientFirstName : "") + " " + (patientLastName ? patientLastName : "");
    this.patientSubmission["mrn"] = mrn ? mrn : patientSubmissionDetails?.patient.mrn;
    this.patientSubmission["dob"] = dob ? dob : patientSubmissionDetails?.patient?.dob;
    this.patientSubmission["totalErrorAndWarnings"] =
      this.patientSubmission.submissions.submissionEWarningCount + this.patientSubmission.submissions.submissionErrorCount;
    this.patientSubmission["picture"] = picture;
    this.patientSubmission["patientId"] = patientId;
    this.patientSubmission["contactInfo"] = contactInfo;
    this.patientSubmission["icdCodes"] = icdCodes;
    this.patientSubmission["issues"].forEach((issue) => {
      //return issue['message'] = issue?.message?.replace(/\n/g, '<br/>');
      return (issue["message"] = reformatWithSpecialCharacters(issue?.message));
    });

    this.patientSubmission["issues"].forEach((issue) => {
      return (issue["questionDescription"] = questionDescription[issue?.question]);
    });

    reformatVisitsAnswersData(this.patientSubmission["issues"]);
    this.patientSubmissionRowSelected = rowIndex;
  }

  hideFromDataRoles() {
    this.hidePatientName = adminRoles.includes(this.currentUser.currentUserRole);
  }

  initializeOptions() {
    const mapCoordinates = swapLatitudeAndLongitude(
      this.patientSubmission.submissionData?.latitude,
      this.patientSubmission.submissionData?.longitude,
    );
    const latitude = mapCoordinates?.latitude;
    const longitude = mapCoordinates?.longitude;

    this.options = {
      center: {
        lat: latitude, //this.patientSubmission?.latitude,
        lng: longitude, //this.patientSubmission?.longitude,
      },
      zoom: 12,
    };
    this.overlays = [
      new google.maps.Marker({
        position: {
          lat: +this.patientSubmission?.latitude,
          lng: +this.patientSubmission?.longitude,
        },
        title: "Selected Submission",
      }),
    ];
  }
  showLocationDialog(visitDate: string, visitType: string) {
    this.selectedVisitDates = visitDate;
    this.selectedVisitType = visitType;
    this.initializeOptions();
    console.log(this.options, this.patientSubmission);
    this.locationPopupDisplay = true;
  }

  getLabelByValue(value: string): string {
    const status = submissionStatus?.find((status) => status?.value === value);
    return status ? status.label : "";
  }
  openeSOCUploadPopup() {
    this.eSOCUploadDisplay = true;
    this.display = false;
  }

  eSOCUploadPopupClose() {
    this.eSOCUploadDisplay = false;
    this.display = false;
  }

  refreshListing() {
    this.getEpisodes();
  }
  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage("globalPageLimit", "perPage", event.rows);
      this.pageNo = event.page;
      this.noOfRecordPerRow = event.rows;
      this.getEpisodes();
    }
  }

  statusColor(status) {
    if (status?.includes("_")) {
      return status?.replace(/_/g, "");
    } else {
      return status?.replace(/-/g, "");
    }
  }

  getCurrentAgencyRole() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
        this.getCaseManagerList();
        this.currentAgency = currentRole;
        if (this.currentAgency.role == RoleID.Admin) {
          this.currentAgency.agency = "";
        }
        this.getEpisodes();
      }),
    );
  }

  adminRoles() {
    this.allowAdminRoles = OnlyAdminAllowedRoles.includes(this.currentUser?.currentUserRole);
  }

  routeTo(e) {
    this.router.navigate(["/" + e]);
  }

  /*  isCaseManagerSearchEmpty() {
    if (this.searchByCaseManager === undefined || this.searchByCaseManager === null || this.searchByCaseManager === '') {
      this.pageNo = 0;
      this.getEpisodes();
    }
  }*/

  resetPageAndFetchEpisodes(searchParam: string) {
    this.setDataInLocalStorage("searchByPatient", this.searchByPatient);
    this.setDataInLocalStorage("searchByCaseManager", this.searchByCaseManager);

    if (searchParam.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.getEpisodes();
    }
  }

  clearSearchAndReload(searchParam: string) {
    if (searchParam === undefined || searchParam === null || searchParam === "") {
      this.getEpisodes();
    }
  }

  onSelectCaseManagerFilter() {
    this.setDataInLocalStorage("selectedCaseManagerName", this.selectedCaseManagerName);
    this.getEpisodes();
  }

  getCaseManagerList() {
    let currentAgency = this.localStorageService.get("currentAgency");
    let requestPayload = {
      agencyId: currentAgency ? currentAgency : {},
    };
    this.patientService.getCaseManagerList(requestPayload).subscribe((res) => {
      if (res?.success) {
        this.caseManagerFilter = res?.content?.distinctCaseManagers
          .filter((caseManager) => caseManager?.fullName != null && caseManager?.fullName.trim() !== "")
          .map((caseManager) => {
            return { label: caseManager?.fullName, value: caseManager?.firstName ? caseManager?.firstName : caseManager?.lastName };
          });

        this.utilService.sortSelectItemByLabel(this.caseManagerFilter);
        this.orignalCaseManagerFilterValues = this.caseManagerFilter.map((obj) => Object.assign({}, obj));
      }
    });
  }

  clearSelectedValues(selectedValues: any[]) {
    if (selectedValues && selectedValues.length) {
      selectedValues.splice(0, selectedValues.length);
      this.setDataInLocalStorage("selectedCaseManagerName", this.selectedCaseManagerName);
      this.getEpisodes();
    }
  }

  sortSelectedValues() {
    this.caseManagerFilter = this.utilService.sortSelectedValuesOfMultiSelect(this.orignalCaseManagerFilterValues, this.selectedCaseManagerName);
  }
}
